/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import React, { useEffect, useState, useRef } from 'react'
import { globalCss, variables, colors } from '../styles/global'
import { animated, useSpring } from 'react-spring'
import { IoIosArrowBack } from 'react-icons/io'
import { IoIosArrowForward } from 'react-icons/io'
import { AiOutlineStar } from 'react-icons/ai'
import { AiFillStar } from 'react-icons/ai'
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby'

import bg from '../images/bgimg4.jpg'

export const Testimonials = ({ testimonials }: any) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [displayAmt, setDisplayAmt] = useState(0)
  const [pageAmt, setPageAmt] = useState(0)
  const [show, setShow] = useState(true)
  const testRef = useRef(0)

  useEffect(() => {
    function handleResize() {
      if (typeof window !== 'undefined') {
        testRef.current = 0
        setDisplayAmt(Math.max(Math.floor((window.innerWidth - 200) / 340), 1))
        setPageAmt(
          Math.ceil(
            testimonials.length / Math.floor((window.innerWidth - 200) / 340)
          )
        )
        setCurrentPage(0)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const increasePage = (amt: number) => {
    setShow(false)
    setTimeout(() => {
      testRef.current += amt
      if (testRef.current > pageAmt - 1) {
        testRef.current = 0
      } else if (testRef.current < 0) {
        testRef.current = pageAmt - 1
      }
      setCurrentPage(testRef.current)
      setShow(true)
    }, 350)
  }

  testimonials.forEach((e: any) => {
    if (e.node.frontmatter.pos === null) {
      e.node.frontmatter.pos = 9999
    }
  })
  const sortedTestimonials = testimonials.sort((a: any, b: any) => {
    return a.node.frontmatter.pos - b.node.frontmatter.pos
  })

  return (
    <div
      className="container"
      css={css`
        width: 100vw;
        position: relative;
        padding: 50px 0 180px 0;
        background-color: ${colors.black};
        ${variables.mobile} {
          padding: 0px 0 180px 0;
        }
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      `}
    >
      <h2
        css={css`
          color: ${colors.black};
          width: 100%;
          flex-shrink: 0;
          white-space: nowrap;
          text-align: center;
          ${variables.mobile} {
            text-align: left;
            white-space: normal;
            margin-left: ${variables.HPaddingMobile}px;
          }
        `}
      >
        KLANTEN VERTELLEN
      </h2>

      <div
        css={css`
          display: flex;
          overflow: hidden;
          padding: 0 50px;
          ${variables.mobile} {
            padding: 0 20px;
          }
        `}
      >
        {sortedTestimonials.map(
          (testimonial: TestimonialType, index: number) => {
            if (
              index >= currentPage * displayAmt &&
              index < (currentPage + 1) * displayAmt
            ) {
              return (
                <Testimonial
                  show={show}
                  text={testimonials[index].node.frontmatter.recensie}
                  namen={testimonials[index].node.frontmatter.namen}
                  datum={testimonials[index].node.frontmatter.datum}
                  stars={testimonials[index].node.frontmatter.stars}
                  align="left"
                  maxLength={200}
                  key={index}
                />
              )
            } else {
              return ''
            }
          }
        )}
      </div>
      <div
        css={css`
          align-self: center;
          display: flex;
          font-size: 1rem;
        `}
      >
        <Link to="/klantenvertellen">
          <u
            css={css`
              color: ${colors.pink};
            `}
          >
            <b>LEES WAT ANDEREN VINDEN</b>
          </u>
        </Link>
      </div>
      {/* <div
        css={css`
          align-self: center;
          display: flex;
        `}
      >
        {[...Array(Math.max(pageAmt, 1))].map((element: any, index: number) => {
          return (
            <SelectionPoint
              selected={index === currentPage ? true : false}
              key={index}
            />
          )
        })}
      </div> */}
      {/* <IoIosArrowBack
        css={css`
          position: absolute;
          top: 50%;
          left: 5px;
          height: 40px;
          width: 40px;
          &:hover {
            cursor: pointer;
          }
        `}
        color={colors.white}
        onClick={() => increasePage(-1)}
      />
      <IoIosArrowForward
        css={css`
          position: absolute;
          top: 50%;
          right: 5px;
          height: 40px;
          width: 40px;
          &:hover {
            cursor: pointer;
          }
        `}
        color={colors.white}
        onClick={() => increasePage(1)}
      /> */}
    </div>
  )
}

type SelectionPointType = {
  selected: boolean
}

function SelectionPoint({ selected }: SelectionPointType) {
  return (
    <div
      css={css`
        border: 1px solid ${colors.white};
        border-radius: 50%;
        height: 8px;
        width: 8px;
        margin: 0 4px -20px;
        background-color: ${selected ? colors.white : 'none'};
      `}
    ></div>
  )
}

export type TestimonialType = {
  text: string
  namen: string
  datum: string
  align: string
  show: boolean
  stars: number
  maxLength: number
}

export function Testimonial({
  text,
  namen,
  datum,
  show,
  maxLength,
  stars,
}: TestimonialType) {
  const [readMore, setReadMore] = useState(false)
  const [innerTxt, setInnerTxt] = useState(text)

  useEffect(() => {
    if (text.length > maxLength) {
      let shortTxt = splitAtFirstWhitespaceBeforeIndex(text, maxLength)
      shortTxt += '...'
      setReadMore(true)
      setInnerTxt(shortTxt)
    }
  }, [])

  return (
    <div
      className={show ? 'show' : 'hide'}
      css={css`
        transition: opacity 0.3s;
        background-color: ${colors.black};
        border: 2px solid ${colors.lightBlue};
        border-radius: 10px;
        padding: 5px 30px 5px 30px;
        margin: 40px;
        width: 100%;
        color: ${colors.white};
        position: relative;
        height: fit-content;
        ${variables.mobile} {
          margin: 0px 0px 40px;
        }
      `}
    >
      <Fade>
        <div>
          <p
            css={css`
              font-size: 1.5rem;
              margin-bottom: 0.3rem;
              color: ${colors.pink};
            `}
          >
            <b>{namen}</b>
          </p>
          <p
            css={css`
              margin-top: 0;
              font-size: 1rem;
            `}
          >
            {datum}
          </p>{' '}
          <hr
            css={css`
              border: 1px solid ${colors.lightBlue};
            `}
          />
          {[...Array(5)].map((star: any, index: number) => {
            if (index < stars) {
              return <AiFillStar key={'star' + index} size="20px" />
            } else {
              return <AiOutlineStar key={'star' + index} size="20px" />
            }
          })}
          <p>
            <i>"{innerTxt}"</i>
          </p>
          {readMore && (
            <div
              css={css`
                margin-bottom: 20px;
              `}
            >
              <a href="/klantenvertellen">
                <u>Lees verder...</u>
              </a>
            </div>
          )}
        </div>
      </Fade>
    </div>
  )
}

function splitAtFirstWhitespaceBeforeIndex(string: string, index: number) {
  let splitIndex = 0
  for (let i = index; i > 0; i--) {
    if (string.charAt(i) == ' ') {
      splitIndex = i
      break
    }
  }

  return string.slice(0, splitIndex)
}

export default Testimonials
