/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import makeCarousel from 'react-reveal/makeCarousel'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { globalCss, variables, colors } from '../styles/global'

import React from 'react'

export const FotoCarousel = () => {
  const fotos = [
    ['/fotos/jolanda.webp', '/fotos/jolanda.jpg'],
    ['/fotos/fabian.webp', '/fotos/fabian.jpg'],
    ['/fotos/tj.webp', '/fotos/tj.jpg'],
    ['/fotos/ruben.webp', '/fotos/ruben.jpg'],
    ['/fotos/dirk.webp', '/fotos/dirk.jpg'],
    ['/fotos/mark.webp', '/fotos/mark.jpg'],
  ]

  const Container = styled.div`
    position: relative;
    overflow: hidden;
    display: block;
    width: auto;
    height: 510px;
    ${variables.mobile} {
      height: 350px;
    }
    margin-bottom: 15px;
  `
  const CarouselUI = ({ children }) => <Container>{children}</Container>
  const Carousel = makeCarousel(CarouselUI)

  return (
    <Carousel defaultWait={3000} maxTurns={1000}>
      {fotos.map((item: any, index: number) => {
        return (
          <Fade duration={500} key={item[0]}>
            <picture
              css={css`
                width: 100%;
                height: 100%;
                object-position: center;
              `}
            >
              <source srcSet={item[0]} type="image/webp" />
              <source srcSet={item[1]} type="image/jpeg" />
              <img
                src={item[0]}
                alt="foto"
                css={css`
                  width: 100%;
                  height: 100%;
                  display: block;
                  object-fit: cover;
                  object-position: center;
                `}
              />
            </picture>
          </Fade>
        )
      })}
    </Carousel>
  )
}

export default FotoCarousel
